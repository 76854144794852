'use strict';

angular.module('mainApp').directive('toggleClass', function (assetConfig, _) {
    return {
        restrict: 'A',
        link: function(scope, el, attr) {
            function split(char) {
                return function(item) {
                    return item.split(char);
                }
            }

            if (attr.toggleClass) {
                _.chain(attr.toggleClass.split(','))
                    .map(split(':'))
                    .each(function(data) {
                        var toggleEl = null;
                        var find = new RegExp(' ?' + data[1], 'ig');

                        angular.element(el).on('click', function () {
                            if (_.isNull(toggleEl)) {
                                toggleEl = document.querySelector(data[0]);
                            }

                            if (_.contains(toggleEl.className, data[1])) {
                                toggleEl.className = toggleEl.className.replace(find, '');
                            }
                            else {
                                toggleEl.className = toggleEl.className + ' ' + data[1];
                            }
                        });
                    }).value()
            }
        }
    }
});
