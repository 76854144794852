'use strict';

angular.module('sdk').factory('DynamicArrayProperty', function (_) {
    var DynamicArrayProperty = function (generator) {
        this._generator = generator;
        this._items = {};
        this._key   = 0;
        this.$path  = null;
    };

    angular.extend(DynamicArrayProperty.prototype, {
        get: function(key) {
            if (key || key === 0) {
                return this._items[key];
            }

            return this._items;
        },

        add: function(code) {
            var item = this._generator(code);
            if (item) {
                this._key++;

                // todo: key construction should be taken outside of this class to be reusable in other projects
                this._items[item.code + '-' + this._key] = item;

                return item.code + '-' + this._key;
            }

            return false;
        },

        inject: function(key, code) {
            // todo: key validation should be taken outside of this class to be reusable in other projects
            // validate key
            var keyParts = key.match(/.*-(\d+)$/);
            if (!keyParts || parseInt(keyParts[1]) < 1) {
                return false;
            }

            this._items[key] = this._generator(code);

            // @_key must be updated for class to operate properly
            if (this._key <= parseInt(keyParts[1])) {
                this._key = parseInt(keyParts[1]) + 1;
            }

            return key;
        },

        remove: function(key) {
            delete this._items[key];
        },

        empty: function() {
            _.forEach(this._items, function(v, key) {
                delete this._items[key];
            });

            this._key = 0;
        },

        itemsList: function() {
            return this.get();
        }
    });

    return DynamicArrayProperty;
});
