'use strict';

angular.module('mainApp').directive('colorList', function (_, $rootScope, $window) {
    return {
        restrict: 'E',
        scope: {
            enableProperty: '=',
            optionData: '=',
            unselectable: '@' // allows to deselect tile
        },
        replace: true,
        templateUrl: function() {
            return 'colorList.html';
        },
        compile: function(element) {
            element.find('tile').attr('tile-template', 'color');
        },
        controller: function($scope) {
            $scope.selectItem = function(itemCode, forcedValue) {
                if (($scope.unselectable === 'true') && ($scope.optionData.value() === itemCode)) {
                    $scope.optionData.value('none', true);
                } else {
                    $scope.optionData.value(itemCode, true);
                }

                if ($scope.enableProperty) {
                    $scope.enableProperty.value($scope.optionData.hasValue() ? 'on' : 'off')
                }

                $scope.$emit('selectItem', itemCode);
                $window.history.back();
            };

            $scope.isSelected = function(itemCode) {
                if ($scope.enableProperty && ($scope.enableProperty.value() === 'off')) {
                    return false;
                }

                return $scope.optionData.value() === itemCode;
            };

            $scope.disable = function() {
                $scope.enableProperty.value('off', true);
            };

            function groupItemList() {
                var groupedItemList = {};

                groupedItemList.none = filter(function(item) {
                    return item.code === 'none'
                });

                groupedItemList.colors = filter(function(item) {
                    return item.code !== 'none' && !item.isCamo;
                });

                groupedItemList.camo = filter(function(item) {
                    return item.isCamo;
                });

                return groupedItemList;
            }

            function filter(fn) {
                var itemMap     = $scope.optionData.$itemMap;
                var legalValues = $scope.optionData.legalValues();

                return _.filter(legalValues, function(code) {
                    return itemMap[code] && fn(itemMap[code]);
                });
            }

            $scope.$watch('optionData.legalValues()', function(legalValues){
                console.log('legalValues', legalValues)
                $scope.itemList = groupItemList();
            })
        }
    }
});
