'use strict';

angular.module('mainApp').directive('navigation', function (menuDataMap, designData, designDataUtils) {

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'navigation.html',
        controller: function($scope) {
            // constants
            var DEFAULT_MENU = 'rootMenu';

            // variables
            var previousMenuName = '';

            // scope
            $scope.menuData = {};

            $scope.designData = designData;

            $scope.$watch('menuName', changeMenu);

            function changeMenu(name) {
                var menuName = name || DEFAULT_MENU,
                    menuData = menuDataMap[menuName];

                if (!menuData) {
                    $scope.$emit('changeMenu', DEFAULT_MENU);

                    return;
                }

                menuData = angular.extend({}, menuData);

                $scope.menuDirection = ((menuData.parent !== previousMenuName) && (menuName !== 'designSummary')) ? 'backward' : 'forward';

                previousMenuName = menuName;

                $scope.menuData = menuData;
            }
        }
    };
});
