'use strict';

// The typical flow in the order process that allows some of the tasks to be skipped if their executors are not present
angular.module('sdk').directive('modalOrder', function (
    $rootScope,
    _,
    $q,
    $timeout,
    dataModelVersion,
    designDataOutput,
    designDataUtils,
    projectIdHandler,
    projectOrderer,
    previewUploader,
    vendorConfig,
    consoleService
) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modal/order.html',
        controller: function($scope) {
            this.registerContactFormScope = function(scope) {
                $scope.contactFormScope = scope;
            };
        },
        link: function(scope, element, attributes) {

            scope.orderType     = vendorConfig.shoppingCartMethod;
            scope.orderComplete = false;
            scope.orderFailure  = false;
            scope.saveStatus    = 'warning';
            scope.saveMessage   = 'Saving the project...';
            scope.modalLocked   = false;
            scope.togglerTitle  = '';
            scope.togglerState  = 'closed';

            var modalEl = $('#' + attributes.id);

            modalEl.on('show.bs.modal', function() {
                unlockModal();
                onSummaryHide();

                $q.all([
                    gatherContactData(), // this will be resolved on form submit
                    saveProject().then(uploadSnapshots).then(setStatusSaveSuccess)
                ])
                    .then(makeApiOrder)
                    .then(setStatusOrderSuccess)
                    .catch(orderErrorHandler)
                    .finally(unlockModal);
            });

            modalEl.on('hide.bs.modal', function(e) {
                if (scope.modalLocked) {
                    e.preventDefault();
                }
            });

            function gatherContactData() {
                if(scope.orderType !== 'email')
                    return;
                if(!scope.contactFormScope)
                    return;
                return scope.contactFormScope.activate()
            }

            function saveProject() {
                scope.saveMessage  = 'Saving the project...';
                scope.errorMessage = 'Failed to save the project!';

                lockModal();

                return projectIdHandler.saveProject()
                    .then(function() {
                        unlockModal();
                    });
            }

            function uploadSnapshots() {
                if(!previewUploader)
                    return;

                scope.saveMessage  = 'Uploading order images...';
                scope.errorMessage = 'Failed to upload order images!';

                return previewUploader.upload(projectIdHandler.getProjectID());
            }

            function makeApiOrder(resultData) {
                var contactFormData = resultData[0]

                scope.saveStatus   = 'warning';
                scope.saveMessage  = 'Processing the inquiry...';
                scope.errorMessage = 'Failed to create order!';

                lockModal();

                var data = {
                  vendor: 'marucci',
                  recipeId: projectIdHandler.getProjectID(),
                  modelVersion: dataModelVersion,
                  method: 'email',
                  contactForm: contactFormData,
                }

                return projectOrderer.order(data).then(function(response){
                  console.log('response', response);
                  
                    if (response.data && response.data.errors) {
                        consoleService.reportError('HttpPostError', 'Unable to make order (status: ' + response.status + ', message: ' + JSON.stringify(response.data) + ')', contactFormData);
                        return $q.reject(response.data.errors)
                    }
                });
            }

            function setStatusOrderSuccess() {
                scope.saveStatus  = 'success';
                scope.saveMessage = 'Order saved!';
                scope.orderComplete = true
            }

            function setStatusSaveSuccess() {
                scope.saveStatus  = 'success';
                scope.saveMessage = 'Project saved!';
            }

            function orderErrorHandler(error) {
                scope.saveStatus  = 'danger';
                scope.saveMessage = scope.errorMessage;
                scope.orderFailure = true
            }

            function lockModal() {
                scope.modalLocked = true;
            }

            function unlockModal() {
                scope.modalLocked = false;
            }

            function onSummaryHide() {
                scope.togglerTitle = 'Show Project Summary';
                scope.togglerState = 'closed';
            }

            function onSummaryShow() {
                scope.togglerTitle = 'Hide Project Summary';
                scope.togglerState = 'open';
            }

            scope.toggleTogglerTitle = function() {
                if (scope.togglerState === 'closed') {
                    onSummaryShow();
                }
                else {
                    onSummaryHide();
                }
            }
        }
    }
});
