'use strict';

angular.module('mainApp').directive('isRosterEmpty', function (_) {

    return {
        restrict: 'A',
        controller: function($scope) {
            $scope.isRosterEmpty = function(rosterDataOutput) {
                return _.every(rosterDataOutput, function(item) {
                    return !item.qty;
                });
            }
        }
    };
});
