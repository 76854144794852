angular.module('mainApp').directive('colorMark', function (_, designData) {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        templateUrl: function() {
            return 'markers/color.html';
        },
        link: function(scope, el, attr) {
            scope.colorProp = _.get(designData, attr.source)
            scope.$watch('colorProp.value()', update);
            function update(value) {
                scope.item = scope.colorProp.item();
            }
        }
    }
});
