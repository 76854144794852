'use strict';

angular.module('mainApp').run(function (dataModelUpgrader, _) {

  function replaceDomain(url) {
    return url && url.replace(
      '//media.x.make.builders/marucci-baseball-jersey/',
      '//customizer-media.orangelv.com/marucci-baseball-jersey/'
    ).replace(
      'http://',
      'https://'
    )
  }

  function fixLogoUrls(logo) {
    return {
      previewFile: replaceDomain(logo.previewFile),
      factoryFile: replaceDomain(logo.factoryFile),
    }
  }

  function mapDesignData(prev) {
    var next = _.defaultsDeep(
      {
        frontLogo: fixLogoUrls(prev.frontLogo),
        sleeveLogo: fixLogoUrls(prev.sleeveLogo),
        teamName: {
          tail: {
            color: prev.teamName.tail.color == null ? 'grey' : prev.teamName.tail.color
          }
        }
      },
      prev
    )
    return next
  }

  function mapConfigData(prev) {
    return {
      dataModelVersion: '1.1.0',
      designData: mapDesignData(prev.designData),
      rosterData: prev.rosterData,
    }
  }

  dataModelUpgrader.registerUpgrader('1.0.0', mapConfigData);
});
