'use strict';

angular.module('mainApp').factory('projectIdHandler', function (_, $q, projectLoader, projectSaver, dataModelVersion, designDataUtils, designDataOutput, updateDesignData, rosterDataOutput) {



    var projectID
    var designDataCache = {}
    var rosterDataCache = []

    function getProjectID() {
        return projectID;
    }

    function unsetProjectID() {
        projectID = null;
    }

    function loadProject(id) {
        if (!id) {
            return;
        }

        if (id === projectID) {
            return;
        }

        projectID = id;

        if(projectID.length === 6) {
          return projectLoader
              .getRecipeId(projectID)
              .then(function (response) {
                  projectID = response.data
                  return projectLoader
                      .load(projectID)
                      .success(loadSuccess);
              })
        }

        return projectLoader
                .load(projectID)
                .success(loadSuccess);
    }

    function flatten(data) {
      var flatData = {}
      function f(object, prefix) {
        return _.each(object, function(value, key) {
          var path = prefix ? prefix+'.'+key : key
          if (value === undefined) {
            return // https://github.com/tgriesser/knex/issues/1563
          } else if (_.isObject(value)) {
            f(value, path) // WE NEED TO GO... DEEPER
            return
          }

          flatData[path] = value
        })
      }

      f(data)
      return flatData
    }

    function unflatten(flatData) {
      var data = {}
      _.each(flatData, function(value, key){
        _.set(data, key, value)
      })
      return data
    }



    function saveProject() {
        if (projectID !== undefined) {
            if (_.isEqual(designDataOutput, designDataCache) && angular.equals(rosterDataOutput, rosterDataCache)) {
                var defer = $q.defer();

                defer.resolve({
                    recipeId: projectID,
                    modelVersion: dataModelVersion,
                });

                return defer.promise;
            }
        }

        var recipeData = {
          modelVersion: dataModelVersion,
          data: flatten(_.assign({ roster: rosterDataOutput, }, designDataOutput))
        }

        return projectSaver
                .save(recipeData)
                .success(saveSuccess);
    }

    function loadSuccess(response) {
        var designData = unflatten(response.data)

        rosterDataOutput.length = 0;
        _.each(designData.roster, function (player) {
            rosterDataOutput.push(player);
        });
        delete designData.roster

        rosterDataCache = _.cloneDeep(rosterDataOutput)
        designDataCache = _.cloneDeep(designData)

        updateDesignData(designData);
    }

    function saveSuccess(data) {
        projectID = data.id;

        // cache data only on save success
        designDataCache = _.cloneDeep(designDataOutput);
        rosterDataCache = _.cloneDeep(rosterDataOutput);
    }



    return {
        'getProjectID': getProjectID,
        'unsetProjectID': unsetProjectID,
        'loadProject': loadProject,
        'saveProject': saveProject
    }
});
