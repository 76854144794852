'use strict';

angular.module('mainApp').directive('modalSave', function ($window, $timeout, projectIdHandler, urlService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modal/save.html',
        link: function(scope, element, attributes) {

            var modalEl = $('#' + attributes.id);

            modalEl.on('show.bs.modal', function() {
                unlockModal();
                saveProject();
            });

            modalEl.on('hide.bs.modal', function(e) {
                if (scope.modalLocked) {
                    e.preventDefault();
                }
            });

            scope.modalLocked = false;

            function saveProject() {
                scope.saveStatus  = 'warning';
                scope.saveMessage = 'Saving the project...';

                lockModal();

                projectIdHandler.saveProject().then(saveSuccess, saveError).finally(unlockModal);
            }

            function saveSuccess() {
                var saveInput = angular.element(document.getElementById('saveInput'));
                saveInput[0].value = urlService.getShareUrl(true);
                saveInput.bind('focus', function() {
                    this.select();
                });

                scope.saveMessage = 'Project saved!';
                scope.saveStatus  = 'success';

                $timeout(function() {
                    saveInput.triggerHandler('focus');
                });
            }

            function saveError() {
                scope.saveStatus  = 'danger';
                scope.saveMessage = 'Failed to save the project!';
            }

            function lockModal() {
                scope.modalLocked = true;
            }

            function unlockModal() {
                scope.modalLocked = false;
            }
        }
    }
});
