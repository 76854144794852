'use strict';

angular.module('mainApp').directive('viewSwitcher', function (_, previewService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: function (element, attributes) {
            return 'viewSwitcher/' + attributes.direction + '.html';
        },
        controller: function ($scope, $element) {
            var adapter = previewService.getAdapter(),
                views = adapter.options.collections;

            if (views.length < 2) {
                $element.remove();
            }

            $scope.activeView = views[0].code;
            $scope.previousView = getPreviousView();
            $scope.nextView = getNextView();

            function getActiveViewIndex() {
                var activeView = _.find(views, function (view, index) {
                    return (view.code === $scope.activeView);
                });

                return views.indexOf(activeView);
            }

            function getPreviousView() {
                var activeViewIndex = getActiveViewIndex(),
                    nextViewIndex = ++activeViewIndex;

                if (nextViewIndex > (views.length - 1)) {
                    nextViewIndex = 0;
                }

                return views[nextViewIndex];
            }

            function getNextView() {
                var activeViewIndex = getActiveViewIndex(),
                    previousViewIndex = --activeViewIndex;

                if (previousViewIndex < 0) {
                    previousViewIndex = views.length;
                    previousViewIndex--;
                }

                return views[previousViewIndex];
            }

            $scope.changeView = function(direction) {
                var activeView;

                switch (direction) {
                    case 'previous': { activeView = getPreviousView().code; } break;
                    case 'next': { activeView = getNextView().code; } break;
                }

                adapter.changeActiveView(activeView);

                $scope.activeView = activeView;
                $scope.previousView = getPreviousView();
                $scope.nextView = getNextView();
            };
        }
    }
});
