'use strict';

angular.module('mainApp').factory('printService', function (_, $timeout, previewService) {
    var snapshots = [];

    function injectSnapshots(cb) {
        previewService.getSnapshots().then(function (images) {
           snapshots = images;
            typeof cb === 'function' && $timeout(cb);
        });
    }

    function flushSnapshots() {
        snapshots = [];
    }

    function getSnapshots() {
        return snapshots;
    }

    return {
        injectSnapshots: injectSnapshots,
        flushSnapshots: flushSnapshots,
        getSnapshots: getSnapshots
    }
});
