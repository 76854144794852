'use strict';

angular.module('mainApp').directive('saveStatus', function ($timeout) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'saveStatus.html',
        link: function(scope, el, attr) {
            scope.$watch('saveStatus', function(status) {
                if (status === 'success') {
                    $timeout(function() {
                        scope.saveStatus = 'success hidden';
                    }, attr.delay ? parseInt(attr.delay) : 2000);
                }
            });
        }
    }
});
