'use strict';

angular.module('mainApp').factory('templateIdHandler', function (designData, updateDesignData, getDesignDataTemplate) {

    var designDataOutput = getDesignDataTemplate( getDefaultTemplateID() );

    function getDefaultTemplateID() {
        return designData.jersey.value();
    }

    function getTemplateID() {
        return designData.jersey.value();
    }

    function applyTemplate(templateID) {
        designData.jersey.value(templateID);
    }

    updateDesignData(designDataOutput);

    return {
        'getDefaultTemplateID' : getDefaultTemplateID,
        'getTemplateID': getTemplateID,
        'applyTemplate': applyTemplate
    }
});
