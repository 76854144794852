'use strict';

angular.module('mainApp').factory('getModifiedDesignDataForPreview', function (_) {
    return function (designDataOutput) {
        designDataOutput = angular.copy(designDataOutput);

        if (_.isEqual(designDataOutput.decoType, 'tackleTwill')) {
            designDataOutput.colors.sleeves = designDataOutput.colors.body
            designDataOutput.colors.placketOrCollar = designDataOutput.colors.body
        }

        return designDataOutput;
    }
});
