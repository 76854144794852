'use strict';

angular.module('mainApp').directive('modalSummary', function ($window, $timeout, projectIdHandler, urlService, constructShareUrl, vendorConfig, base64Service, printService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'modal/summary.html',
        link: function(scope, element, attributes) {

            var modalEl = $('#' + attributes.id);

            modalEl.on('show.bs.modal', function() {
                unlockModal();
                saveProject();
            });

            modalEl.on('hide.bs.modal', function(e) {
                if (scope.modalLocked) {
                    e.preventDefault();
                }
                else {
                    printService.flushSnapshots();
                    scope.printEnabled = false;
                    scope.$apply();
                }
            });

            scope.printEnabled = false;
            scope.modalLocked  = false;

            scope.print = function() {
                if (scope.printEnabled) {
                    $window.print();
                }
            };


            var shareUrl;

            function getShareUrl() {
                var url = urlService.getShareUrl(true);

                if (vendorConfig.landingPageUrl && (vendorConfig.landingPageUrl === 'none')) {
                    url += ('?config=' + base64Service.encode(JSON.stringify(vendorConfig)));
                }

                return constructShareUrl(encodeURIComponent(url));
            }

            function saveProject() {
                scope.saveStatus  = 'warning';
                scope.saveMessage = 'Saving the project...';

                lockModal();

                projectIdHandler.saveProject().then(saveSuccess, saveError).finally(unlockModal);
            }

            function saveSuccess() {

                shareUrl = getShareUrl();

                scope.saveMessage = 'Project saved!';
                scope.saveStatus  = 'success';
                scope.mailtoLink  = shareUrl.mail;

                printService.injectSnapshots(function() {
                    scope.printEnabled = true;
                    scope.$apply();
                });
            }

            function saveError() {
                scope.saveStatus  = 'danger';
                scope.saveMessage = 'Failed to save the project!';
            }

            scope.share = function(socialServiceName) {
                window.open(shareUrl[socialServiceName], '', 'width=626, height=436');
            };

            function lockModal() {
                scope.modalLocked = true;
            }

            function unlockModal() {
                scope.modalLocked = false;
            }
        }
    }
});
