'use strict';

angular.module('mainApp').directive('shareButtons', function (vendorConfig, urlService, constructShareUrl, base64Service) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'shareButtons.html',
        scope: {},
        link: function(scope, el, attr) {

            var shareUrl;

            scope.urlService = urlService;

            scope.$watch('urlService.getProjectID()', change);

            function getShareUrl() {
                var url = urlService.getShareUrl(true);

                if (vendorConfig.landingPageUrl && (vendorConfig.landingPageUrl === 'none')) {
                    url += ('?config=' + base64Service.encode(JSON.stringify(vendorConfig)));
                }

                return constructShareUrl(encodeURIComponent(url));
            }

            function change() {
                shareUrl = getShareUrl();
                scope.mailtoLink = shareUrl.mail;
            }

            scope.share = function(socialServiceName) {
                window.open(shareUrl[socialServiceName], '', 'width=626, height=436');
            };
        }
    }
});
