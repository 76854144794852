'use strict';

angular.module('mainApp').directive('switchTile', function (_, $rootScope) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            optionData: '='
        },
        templateUrl: function() {
            return 'tiles/switchTile.html';
        },
        link: function(scope, el) {

            var inputEl = $(el.find('input'))
            scope.$watch('optionData.value()', function(value){
                inputEl.bootstrapSwitch('state', isOn(value), true)
            })

            inputEl.on('switchChange.bootstrapSwitch', function(e, state) {
                selectItem(getCodeByState(state))
            });
            function selectItem(code) {
                scope.$apply(function(){
                    scope.optionData.value(code);
                });
            }

            function getCodeByState(state) {
                if (state === false) {
                    return _.find(scope.optionData.legalValues(), isOff)
                }
                else {
                    return _.find(scope.optionData.legalValues(), isOn)
                }
            }

            function isOff(code) {
                return _.contains(['off', 'none'], code);
            }

            function isOn(code) {
                return !isOff(code);
            }
        }
    }
});
