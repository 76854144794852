'use strict';

angular.module('mainApp').factory('activeGarmentService', function (designData, designDataUtils, LimitedProperty, designDataOutput) {

    var garmentKey = -1;

    var garments = require('data').garments

    function getItem() {
        var garmentData = getItemData();
        if (garmentData) {
            return garments[garmentData.code];
        }

        return null;
    }

    function getItemData() {
        var garmentData = designData.garmentList.get(garmentKey);
        if (garmentData) {
            return garmentData;
        }

        return null;
    }

    function set(key) {
        garmentKey  = key;
        var garment = getItem();

        designDataOutput.assetId = garment.index + "_" + garment.code;
        designDataOutput.bodyColor = designData.garmentList.get(key).bodyColor.value();
    }

    function key() {
        return garmentKey;
    }

    return {
        itemData: getItemData,
        item: getItem,
        set: set,
        key: key
    };
});
