'use strict';

angular.module('mainApp').factory('shadowBlendingMethod', function () {

    return function (canvas, layer) {
        var canvasImageData = canvas.data,
            layerImageData = layer.data,
            i,l;

        var highlightCount = 0;
        var shadowCount = 0;

        for (i = 0, l = canvasImageData.length; i < l; i += 4) {
            if (canvasImageData[i + 3] === 0) {
                continue;
            }

            //shadow multiply
            var shadow = layerImageData[i+3];
            if(shadow < 255) {
                canvasImageData[i]          = canvasImageData[i] * shadow / 255;
                canvasImageData[i + 1]      = canvasImageData[i + 1] * shadow / 255;
                canvasImageData[i + 2]      = canvasImageData[i + 2] * shadow / 255;
            }

            //highlight screen
            var highlight = layerImageData[i];
            if(highlight > 0) {
                // highlightCount++;
                canvasImageData[i]          = 255 - (255 - canvasImageData[i]) * (255 - highlight) / 255;
                canvasImageData[i + 1]      = 255 - (255 - canvasImageData[i + 1]) * (255 - highlight) / 255;
                canvasImageData[i + 2]      = 255 - (255 - canvasImageData[i + 2]) * (255 - highlight) / 255;
            }
        }
        // console.log('highlightCount', highlightCount)
        // console.log('shadowCount', shadowCount)

        return canvas;
    }
});
