'use strict';

angular.module('mainApp').factory('previewAdapters', function ($location, CanvasAdapter, designData) {

    var views = [
        { code: 'front', name: 'Front' },
        { code: 'right', name: 'Right' },
        { code: 'back', name: 'Back' },
        { code: 'left', name: 'Left' },
        //{ code: 'icon', name: 'Icon' },
    ];

    function getSize(height) {
        return {
            id: 'x' + height,
            height: height,
            width: height
        }
    }

    return {
        'adapters': {
            'canvas': new CanvasAdapter({
                'snapshotList': [
                    { viewName: 'front', size: 'x720' },
                    { viewName: 'left', size: 'x720' },
                    { viewName: 'back', size: 'x720' },
                    { viewName: 'right', size: 'x720' },
                ],
                'collections': views,
                'sizes': {
                    /*
                    'icon': [
                        getSize(2048),
                    ],
                    'front': [
                        getSize(2048),
                    ],
                    'left': [
                        getSize(2048),
                    ],
                    'back': [
                        getSize(2048),
                    ],
                    'right': [
                        getSize(2048),
                    ],
                    */
                    /**/
                    'icon': [
                        getSize(280),
                        //getSize(360),
                        //getSize(440),
                        //getSize(512),
                        //getSize(540),
                        getSize(720),
                        //getSize(900),
                        getSize(1024),
                        //getSize(1536),
                        //getSize(2048),
                    ],
                    'front': [
                        getSize(280),
                        //getSize(360),
                        //getSize(440),
                        //getSize(512),
                        //getSize(540),
                        getSize(720),
                        //getSize(900),
                        getSize(1024),
                        //getSize(1536),
                        //getSize(2048),
                    ],
                    'left': [
                        getSize(280),
                        //getSize(360),
                        //getSize(440),
                        //getSize(512),
                        //getSize(540),
                        getSize(720),
                        //getSize(900),
                        getSize(1024),
                        //getSize(1536),
                        //getSize(2048),
                    ],
                    'back': [
                        getSize(280),
                        //getSize(360),
                        //getSize(440),
                        //getSize(512),
                        //getSize(540),
                        getSize(720),
                        //getSize(900),
                        getSize(1024),
                        //getSize(1536),
                        //getSize(2048),
                    ],
                    'right': [
                        getSize(280),
                        //getSize(360),
                        //getSize(440),
                        //getSize(512),
                        //getSize(540),
                        getSize(720),
                        //getSize(900),
                        getSize(1024),
                        //getSize(1536),
                        //getSize(2048),
                    ],
                    /**/
                }
            }),
        },
        'views': views
    }
});
