angular.module('mainApp').directive('visitedMark', function (designData, menuService) {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        templateUrl: function() {
            return 'markers/visited.html';
        },
        link: function(scope, el, attr) {
            function update() {
                var visitedList = menuService.getVisitedMenus();
                var visitedType = 'none';

                if (_.has(visitedList, attr.source)) {
                    visitedType = visitedList[attr.source];
                }

                scope.type = visitedType;
            }

            scope.designData = designData;

            if (attr.state) {
                scope.type = attr.state;
            }
            else {
                scope.$watchCollection('designData', update, true);
            }
        }
    }
});
