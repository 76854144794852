angular.module('sdk').directive('setOffset', function ($rootScope, $window, $timeout, vendorConfig) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var timeout;
            var header = $('.header');

            if (!header.length) {
                return;
            }

            function addRemoveClass() {
                var offset = '';

                if ($window.innerWidth < vendorConfig.mobileTriggerWidth) {
                    // height of header must be subtracted
                    offset = Math.max($window.innerHeight, $window.innerWidth) - $(header).outerHeight();
                }

                $(element).css('margin-top', offset);
            }

            angular.element($window).bind('resize', function() {
                $timeout.cancel(timeout);
                timeout = $timeout(addRemoveClass, 50);
            });

            angular.element($window).bind('orientationchange', addRemoveClass);

            addRemoveClass();
        }
    }
});
