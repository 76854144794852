'use strict';

angular.module('mainApp').directive('printView', function ($timeout, printService, urlService) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'printView.html',
        link: function(scope, el, attr) {
            scope.getSnapshots = printService.getSnapshots;
            scope.snapshots    = printService.getSnapshots();
            scope.urlService   = urlService;

            scope.$watch('getSnapshots()', function(snapshots) {
                scope.snapshots = snapshots;
            });

        }
    }
});
