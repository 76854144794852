'use strict';

angular.module('mainApp').controller('DesignTileController', function ($scope, designData) {

    $scope.jersey = designData.jersey
    $scope.$watch('jersey.value()', updateIconBg)
    $scope.$watch('item', updateIconBg)

    function updateIconBg(){
        var iconUrl = $scope.item.iconTemplate.replace('#JERSEY#', $scope.jersey.item().assetCode)
        $scope.iconBg = {
            'background-image': 'url('+iconUrl+')'
        }
    }
});
