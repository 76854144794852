'use strict';

angular.module('mainApp').factory('templateWatcher', function (_, $rootScope, designData, menuDataMap) {

    var data = require('data')
    var whitelists = data.whitelists

    // watchers
    var watcherScope = $rootScope.$new(true);
    watcherScope.designData = designData;
    function updateTailLegalValues(item, previous) {
        if (!_.isEmpty(item)) {
            // update tail legalValues
            var layout = designData.teamName.layout.item();
            var font   = designData.teamName.font.item();
            var types  = _.intersection(layout.tailList, font.tailList);
            //console.log('TAIL TYPES %o <--', types, layout.tailList, font.tailList);
            designData.teamName.tail.type.legalValues(types);

            if (_.isEmpty(types)) {
                designData.teamName.tail.enabled.legalValues(['off']);
            }
            else {
                designData.teamName.tail.enabled.legalValues(whitelists.toggles.all);
            }
        }
    }

    function updateDesignLegalValues(item, previous) {
        var decoType = designData.decoType.value();
        if (!_.isEmpty(item)) {
            designData.design.legalValues(_.isEqual(decoType, 'tackleTwill') ? ['traditional'] : item.designList);
        }
    }

    function setLocationNone(target) {
        return function(current, previous) {
            if (current !== 'none') {
                designData[target].location.value('none');
            }
        }
    }

    function updatePipingLegalValues() {
        var jersey = designData.jersey.item();
        var design = designData.design.item();
        var intersection = [];

        if (jersey && design) {
            intersection = _.intersection(jersey.pipingLocationList, design.pipingLocationList);
        }

        if (_.contains(intersection, 'sleeve')) {
            designData.piping.sleevePiping.legalValues(whitelists.toggles.all);
        }
        else {
            designData.piping.sleevePiping.legalValues(['off']);
        }

        if (_.contains(intersection, 'placket')) {
            designData.piping.placketPiping.legalValues(whitelists.toggles.all);
        }
        else {
            designData.piping.placketPiping.legalValues(['off']);
        }
    }

    function updateJerseyElementAvailability() {
        var jersey = designData.jersey.item();
        var decoType = designData.decoType.value()

        if (!jersey) {
            return;
        }

        if (_.isEqual(decoType, 'tackleTwill')) {
            menuDataMap.placket.disabled = true
            menuDataMap.sleeves.disabled = true
        }
        else {
            menuDataMap.placket.disabled = !jersey.hasPlacket
            menuDataMap.collar.disabled = !jersey.hasCollar
            menuDataMap.sleeves.disabled = false
        }
    }

    function updateDesignColors() {
        var design = designData.design.item();
        var decoType = designData.decoType.value();
        if (!design) {
            return;
        }

        _.each(design.colorNameList, function(item, key) {
            designData.colors[key].legalValues(_.isNull(item) ? ['none'] : whitelists.colors.solid);
            menuDataMap[key].titleDynamic = item
            menuDataMap[key].disabled = _.isNull(item)
        })

        if (_.isEqual(decoType, 'tackleTwill')) {
            _.chain(designData.colors).invoke('legalValues', ['none'])
            designData.colors.body.legalValues(whitelists.colors.tackleTwill)
        }
        else {
            _.each(designData.colors, function(item, color) {
                if (_.isNull(design.colorNameList[color])) {
                    item.legalValues(['none'])
                }
                else {
                    item.legalValues(whitelists.colors.solidAndCamo)
                }
            })
        }
    }

    function onDecoTypeChange() {
        var decoType = designData.decoType.value();

        designData.jersey.legalValues(whitelists.garments[decoType]);

        var legalColorCodes = whitelists.colors.solidAndCamo;
        if (_.isEqual(decoType, 'tackleTwill')) {
            legalColorCodes = whitelists.colors.solid;
        }

        _.invoke([
            designData.teamName.color,
            designData.playerName.color,
            designData.number.color
        ], 'legalValues', legalColorCodes);



        if (_.isEqual(decoType, 'tackleTwill')) {
            designData.frontLogo.location.legalValues(['none']);
            designData.sleeveLogo.location.legalValues(['none']);
        }
        else {
            designData.frontLogo.location.legalValues(data.decos.frontLogo.decoLocationList);
            designData.sleeveLogo.location.legalValues(data.decos.sleeveLogo.decoLocationList);

        }

        // functions below properly sets legalValues depending on decoType
        updateDesignLegalValues(designData.jersey.item());
        updateDesignColors();
    }

    watcherScope.$watch('designData.decoType.value()', onDecoTypeChange);

    watcherScope.$watch('designData.teamName.location.value()', setLocationNone('frontLogo'));
    watcherScope.$watch('designData.frontLogo.location.value()', setLocationNone('teamName'));

    watcherScope.$watch('designData.teamName.layout.item()', updateTailLegalValues);
    watcherScope.$watch('designData.teamName.font.item()', updateTailLegalValues);

    watcherScope.$watch('designData.jersey.item()', updateDesignLegalValues);

    watcherScope.$watch('designData.jersey.item()', updatePipingLegalValues);
    watcherScope.$watch('designData.design.item()', updatePipingLegalValues);

    watcherScope.$watch('designData.jersey.item()', updateJerseyElementAvailability);
    watcherScope.$watch('designData.design.item()', updateDesignColors);
});

angular.module('mainApp').run(function (templateWatcher) {
    //force to activate templateWatcher
});
