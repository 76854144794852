'use strict';

angular.module('mainApp').factory('getDesignDataTemplate', function() {
    var designDataTemplate = {

        decoType: 'sublimation',

        jersey: '2ButtonHenley-raglan-sublimation',

        design: 'traditional',

        piping: {
            sleevePiping: 'off',
            placketPiping: 'off',
            color1: 'red',
            color2: 'red'
        },

        colors: {
            body: 'red',
            designColor1: 'tennesseeOrange',
            designColor2: 'kelly',
            designColor3: 'purple',
            sleeves: 'grey',
            placketOrCollar: 'athleticGold',
        },

        teamName: {
            location: 'fullFront',
            text: 'Team Name',
            color: 'red',
            outlineColor: 'navy',
            font: 'script',
            layout: 'straight',
            tail: {
                enabled: 'on',
                type: 'jet',
                text: 'Tail Text',
                font: 'script',
                color: 'grey'
            }
        },

        frontLogo: {
            location: 'none',
            previewFile: null,
            factoryFile: null
        },

        sleeveLogo: {
            location: 'leftSleeve',
            previewFile: null,
            factoryFile: null
        },

        number: {
            frontLocation: 'lowerLeftChest',
            backLocation: 'fullBack',
            number: '23',
            color: 'red',
            outlineColor: 'navy',
            font: 'proBlock'
        },

        playerName: {
            location: 'upperBack',
            text: 'Player Name',
            color: 'red',
            outlineColor: 'navy',
            font: 'proBlock',
            layout: 'straight'
        }
    };

    return function (templateID) {
        var designData = angular.copy(designDataTemplate);
        return designData;
    }
});
