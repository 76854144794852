'use strict';

angular.module('mainApp').controller('GarmentListController', function ($scope, $filter, designData, designDataUtils) {

    var garmentFilter = $filter('garmentFilter');
    var garmentList   = designDataUtils.garmentList;

    function updateFilter() {
        garmentList.legalValues(garmentFilter());
    }

    $scope.templateList = garmentList;
    $scope.category = designData.filter.category;

});
