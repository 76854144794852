'use strict';

angular.module('mainApp').factory('designData', function(
    $rootScope,
    TextProperty,
    FileProperty,
    LimitedProperty
){
    var data = require('data')
    var whitelists = data.whitelists
    var decos = data.decos;

    // console.log(JSON.stringify(data, null, 4))
    // console.log(JSON.stringify(whitelists, null, 4))

    return {
        decoType:           new LimitedProperty(whitelists.decoTypes.all, data.decoTypes),

        jersey:             new LimitedProperty(whitelists.garments.all, data.garments),

        design:             new LimitedProperty([], data.designs),

        piping: {
            sleevePiping:   new LimitedProperty(whitelists.toggles.all, data.toggles),
            placketPiping:  new LimitedProperty(whitelists.toggles.all, data.toggles),
            color1:         new LimitedProperty(whitelists.colors.solid, data.colors),
            color2:         new LimitedProperty(whitelists.colors.solidOptional, data.colors)
        },

        colors: {
            body:           new LimitedProperty(whitelists.colors.solidAndCamo, data.colors),
            designColor1:   new LimitedProperty(whitelists.colors.none, data.colors),
            designColor2:   new LimitedProperty(whitelists.colors.none, data.colors),
            designColor3:   new LimitedProperty(whitelists.colors.none, data.colors),
            sleeves:        new LimitedProperty(whitelists.colors.solidAndCamo, data.colors),
            placketOrCollar: new LimitedProperty(whitelists.colors.solidAndCamo, data.colors),
        },

        teamName: {
            location:       new LimitedProperty(decos.teamname.decoLocationList, data.decoLocations),
            text:           new TextProperty(),
            color:          new LimitedProperty(whitelists.colors.solidAndCamo, data.colors),
            outlineColor:   new LimitedProperty(whitelists.colors.solidOptional, data.colors),
            font:           new LimitedProperty(whitelists.fonts.all, data.fonts),
            layout:         new LimitedProperty(whitelists.layouts.all, data.layouts),
            tail: {
                enabled:    new LimitedProperty(whitelists.toggles.all, data.toggles),
                type:       new LimitedProperty(whitelists.tailings.all, data.tailings),
                text:       new TextProperty(),
                font:       new LimitedProperty(whitelists.fonts.all, data.fonts),
                color:      new LimitedProperty(whitelists.colors.solid, data.colors)
            }
        },

        frontLogo: {
            location:       new LimitedProperty(decos.frontLogo.decoLocationList, data.decoLocations),
            previewFile:    new FileProperty(),
            factoryFile:    new FileProperty()
        },

        sleeveLogo: {
            location:       new LimitedProperty(decos.sleeveLogo.decoLocationList, data.decoLocations),
            previewFile:    new FileProperty(),
            factoryFile:    new FileProperty()
        },

        number: {
            frontLocation:  new LimitedProperty(decos.frontNumber.decoLocationList, data.decoLocations),
            backLocation:   new LimitedProperty(decos.backNumber.decoLocationList, data.decoLocations),
            number:         new TextProperty(), // only for preview - actual numbers are set in roster
            color:          new LimitedProperty(whitelists.colors.solidAndCamo, data.colors),
            outlineColor:   new LimitedProperty(whitelists.colors.solidOptional, data.colors),
            font:           new LimitedProperty(decos.backNumber.fontList, data.fonts)
        },

        playerName: {
            location:       new LimitedProperty(decos.playerName.decoLocationList, data.decoLocations),
            text:           new TextProperty(), // only for preview - actual numbers are set in roster
            color:          new LimitedProperty(whitelists.colors.solidAndCamo, data.colors),
            outlineColor:   new LimitedProperty(whitelists.colors.solidOptional, data.colors),
            font:           new LimitedProperty(decos.playerName.fontList, data.fonts),
            layout:         new LimitedProperty(decos.playerName.layoutList, data.layouts)
        }
    };
});
