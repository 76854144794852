'use strict';

angular.module('mainApp').factory('designDataUtils', function (_, designData, rosterDataOutput) {

    function resolvePath(data, path) {
        var obj = _.cloneDeep(data);
        _.each(path.split('.'), function(key) {
            obj = obj[key];
        });

        return obj;
    }

    function canHaveColors() {
        return !_.isEqual(designData.decoType.value(), 'tackleTwill');
    }

    function hasButtons() {
        return designData.jersey.item().hasButtons;
    }

    function hasLogo(target) {
        return function() {
            return _.isEqual(designData[target].location.value(), 'none') && designData[target].previewFile.value();
        }
    }

    function hasPiping() {
        return _.any([designData.piping.sleevePiping, designData.piping.placketPiping], function(location) {
            return _.isEqual(location.value(), 'on');
        });
    }

    function hasNumber() {
        return _.any([designData.number.frontLocation, designData.number.backLocation], function(location) {
            return !_.isEqual(location.value(), 'none');
        });
    }

    function hasLegalValues(path) {
        var data = resolvePath(designData, path);
        return data && !_.isEmpty(data.$legalValueList);
    }

    function hasRosterData() {
        return _.any(rosterDataOutput, function(size) {
            return size.qty > 0;
        })
    }

    function canHavePiping(type) {
        var jersey = designData.jersey.item();
        var design = designData.design.item();

        if (!jersey || !design) {
            return false;
        }

        var intersection = _.intersection(jersey.pipingLocationList, design.pipingLocationList);

        if (!_.isEmpty(type)) {
            return _.contains(intersection, type)
        }
        else {
            return !_.isEmpty(intersection);
        }
    }

    function getColorMappingList() {
        var design = designData.design.item();
        return design.colorNameList || {};
    }

    function getMappedColorName(key) {
        var design = designData.design.item();
        return design && design.colorNameList[key];
    }

    function getTileListClass(itemList) {
        if (itemList.length == 3) {
            return 'col-xs-4 col-sm-4 col-md-4 col-lg-4';
        }
        else {
            'col-xs-6 col-md-4';
        }
    }

    function isSleeveLogoEnabled() {
        return !_.isEqual(designData.sleeveLogo.location.value(), 'none');
    }

    function isFrontLogoEnabled() {
        return !_.isEqual(designData.frontLogo.location.value(), 'none');
    }

    function isTeamNameEnabled() {
        return !_.isEqual(designData.teamName.location.value(), 'none');
    }

    function isTailingEnabled() {
        return _.isEqual(designData.teamName.tail.enabled.value(), 'on');
    }

    function isPlayerNameEnabled() {
        return !_.isEqual(designData.playerName.location.value(), 'none');
    }

    function isSubmenuAvailable(submenu) {
        var decoType = designData.decoType.value();
        var limitations = require('data').decoTypeLimitations[decoType];
        if (limitations && limitations.hideNavigation[submenu]) {
            return false;
        }

        return true;
    }

    return {
        hasRosterData: hasRosterData,

        hasFrontLogo: hasLogo('frontLogo'),
        hasSleeveLogo: hasLogo('sleeveLogo'),

        hasLegalValues: hasLegalValues,
        hasPiping: hasPiping,
        hasNumber: hasNumber,
        canHavePiping: canHavePiping,

        getColorMappingList: getColorMappingList,
        getMappedColorName: getMappedColorName,

        getTileListClass: getTileListClass,

        isNumberEnabled: hasNumber,
        isSleeveLogoEnabled: isSleeveLogoEnabled,
        isFrontLogoEnabled: isFrontLogoEnabled,
        isTeamNameEnabled: isTeamNameEnabled,
        isTailingEnabled: isTailingEnabled,
        isPlayerNameEnabled: isPlayerNameEnabled,

        isSubmenuAvailable: isSubmenuAvailable,

        canHaveColors: canHaveColors,
    }
});
