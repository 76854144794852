'use strict';

angular.module('mainApp').directive('contactForm', function ($q, vendorConfig) {
    return {
        restrict: 'A',
        require: '^modalOrder',
        // scope: {},
        link: function(scope, element, attributes, orderCtrl) {
            scope.orderType = vendorConfig.shoppingCartMethod;
            scope.formData = {};
            scope.formSubmitted = false;
            scope.formComplete = false;
            var _deferred = null;

            scope.activate = function() {
                if(!_deferred)
                    _deferred = $q.defer();
                return _deferred.promise;
            };

            scope.submitForm = function(form) {
                scope.formSubmitted = true;

                if (form.$invalid) {
                    return;
                }
                scope.formComplete = true;
                _deferred.resolve(scope.formData);
                _deferred = null;
            };

            orderCtrl.registerContactFormScope(scope);
        }
    }
});
