'use strict';

angular.module('sdk').factory('_', function() {
    return require('lodash')
})

angular.module('mainApp', ['sdk', 'default-routes']);

angular.module('mainApp').config(function ($analyticsProvider) {
    $analyticsProvider.virtualPageviews(false);
});

angular.module('mainApp').value('imageServerPath', '/api/images');

angular.module('mainApp').value('dataModelVersion', '0');

angular.module('mainApp').value('loadSaveOrderConfig', {
    saveUrl: '/api/recipes?modelVersion=0',
    loadUrl: '/api/recipes/{projectID}?modelVersion=0',
    orderUrl: '/api/orders'
});

angular.module('mainApp').value('vendorConfig', {
    mobileTriggerWidth: 768,
    ui: {
        orderSectionTitle: "Order inquiry",
        shareSectionTitle: "Share"
    }
});

angular.module('mainApp').value('projectConfig', {
    defaultMenu: 'jerseyChoice'
});

angular.module('mainApp').value('assetConfig', {
    minorVersion: '2015-05-25'
});
