'use strict';

angular.module('mainApp').directive('onOffOrdering', function (_) {

    return {
        restrict: 'A',
        controller: function($scope) {
            $scope.onOffOrdering = function (code) {
                return _.contains(['none', 'off'], code) ? 1 : -1;
            }
        }
    };
});
