'use strict';

angular.module('mainApp').factory('menuDataMap', function ($rootScope, _, designData) {
    var menuDataMap = {
        'rootMenu': {
            id: 'rootMenu',
            title: 'Menu',
            template: 'menu/root.html'
        },
        'jerseyChoice': {
            id: 'jerseyChoice',
            title: 'Jersey Choice',
            parent: 'rootMenu',
            template: 'menu/selectJersey.html',
            jerseyList: designData.jersey,
            decorationTypes: designData.decoType
        },
        'design': {
            id: 'design',
            title: 'Design',
            parent: 'rootMenu',
            template: 'menu/selectDesign.html',
            decoType: designData.decoType,
            jersey: designData.jersey,
            designList: designData.design
        },
        'roster': {
            id: 'roster',
            title: 'Roster',
            parent: 'rootMenu',
            template: 'submenu/roster.html'
        },

        // COLOR
        'colors': {
            id: 'colors',
            title: 'Colors',
            parent: 'rootMenu',
            template: 'menu/colorList.html'
        },
        'body': {
            id: 'body',
            title: 'Body',
            parent: 'colors',
            template: 'submenu/colors.html',
            colors: designData.colors.body
        },
        'designColor1': {
            id: 'designColor1',
            title: 'Design Color 1',
            parent: 'colors',
            template: 'submenu/colors.html',
            colors: designData.colors.designColor1
        },
        'designColor2': {
            id: 'designColor2',
            title: 'Design Color 2',
            parent: 'colors',
            template: 'submenu/colors.html',
            colors: designData.colors.designColor2
        },
        'designColor3': {
            id: 'designColor3',
            title: 'Design Color 3',
            parent: 'colors',
            template: 'submenu/colors.html',
            colors: designData.colors.designColor3
        },
        'sleeves': {
            id: 'sleeves',
            title: 'Sleeves',
            parent: 'colors',
            template: 'submenu/colors.html',
            colors: designData.colors.sleeves
        },
        'placket': {
            id: 'placket',
            title: 'Placket',
            parent: 'colors',
            template: 'submenu/colors.html',
            colors: designData.colors.placketOrCollar
        },
        'collar': {
            id: 'collar',
            title: 'Collar',
            parent: 'colors',
            template: 'submenu/colors.html',
            colors: designData.colors.placketOrCollar
        },

        // LOGO
        'frontLogo': {
            id: 'frontLogo',
            title: 'Front Logo',
            parent: 'rootMenu',
            template: 'menu/logoImage.html',
            location: designData.frontLogo.location,
            previewFile: designData.frontLogo.previewFile,
            factoryFile: designData.frontLogo.factoryFile
        },

        'sleeveLogo': {
            id: 'sleeveLogo',
            title: 'Sleeve Logo',
            parent: 'rootMenu',
            template: 'menu/logoImage.html',
            location: designData.sleeveLogo.location,
            previewFile: designData.sleeveLogo.previewFile,
            factoryFile: designData.sleeveLogo.factoryFile
        },

        // PIPING
        'piping': {
            id: 'piping',
            title: 'Piping',
            parent: 'rootMenu',
            template: 'menu/piping.html',
            sleevePiping: designData.piping.sleevePiping,
            placketPiping: designData.piping.placketPiping,
        },
        'pipingColor1': {
            id: 'pipingColor1',
            title: 'Color 1',
            parent: 'piping',
            template: 'submenu/colors.html',
            colors: designData.piping.color1
        },
        'pipingColor2': {
            id: 'pipingColor2',
            title: 'Color 2',
            parent: 'piping',
            template: 'submenu/colors.html',
            colors: designData.piping.color2
        },

        // NUMBER
        'number': {
            id: 'number',
            title: 'Number',
            parent: 'rootMenu',
            template: 'menu/number.html',
            frontLocation: designData.number.frontLocation,
            backLocation: designData.number.backLocation,
            number: designData.number.number
        },
        'numberFont': {
            id: 'numberFont',
            title: 'Font',
            parent: 'number',
            template: 'submenu/fonts.html',
            fonts: designData.number.font
        },
        'numberColor': {
            id: 'numberColor',
            title: 'Color',
            parent: 'number',
            template: 'submenu/colors.html',
            colors: designData.number.color
        },
        'numberOutlineColor': {
            id: 'numberOutlineColor',
            title: 'Outline Color',
            parent: 'number',
            template: 'submenu/colors.html',
            colors: designData.number.outlineColor
        },

        // TEAM NAME
        'teamName': {
            id: 'teamName',
            title: 'Team Name',
            parent: 'rootMenu',
            template: 'menu/teamName.html',
            text: designData.teamName.text,
            location: designData.teamName.location
        },
        'teamNameFont': {
            id: 'teamNameFont',
            title: 'Font',
            parent: 'teamName',
            template: 'submenu/fonts.html',
            fonts: designData.teamName.font
        },
        'teamNameColor': {
            id: 'teamNameColor',
            title: 'Color',
            parent: 'teamName',
            template: 'submenu/colors.html',
            colors: designData.teamName.color
        },
        'teamNameOutlineColor': {
            id: 'teamNameOutlineColor',
            title: 'Outline Color',
            parent: 'teamName',
            template: 'submenu/colors.html',
            colors: designData.teamName.outlineColor
        },
        'teamNameLayout': {
            id: 'teamNameLayout',
            title: 'Layout',
            parent: 'teamName',
            template: 'submenu/layouts.html',
            layouts: designData.teamName.layout
        },

        // TAIL
        'tailing': {
            id: 'tailing',
            title: 'Tailing',
            parent: 'teamName',
            template: 'menu/tailing.html',
            tails: designData.teamName.tail.type,
            text: designData.teamName.tail.text,
            enabled: designData.teamName.tail.enabled
        },
        'tailingColor': {
            id: 'tailingColor',
            title: 'Text Color',
            parent: 'tailing',
            template: 'submenu/colors.html',
            colors: designData.teamName.tail.color
        },
        'tailingFont': {
            id: 'tailingFont',
            title: 'Text Font',
            parent: 'tailing',
            template: 'submenu/fonts.html',
            fonts: designData.teamName.tail.font
        },

        // PLAYER NAME
        'playerName': {
            id: 'playerName',
            title: 'Player Name',
            parent: 'rootMenu',
            template: 'menu/playerName.html',
            location: designData.playerName.location,
            text: designData.playerName.text
        },
        'playerNameFont': {
            id: 'playerNameFont',
            title: 'Font',
            parent: 'playerName',
            template: 'submenu/fonts.html',
            fonts: designData.playerName.font
        },
        'playerNameColor': {
            id: 'playerNameColor',
            title: 'Color',
            parent: 'playerName',
            template: 'submenu/colors.html',
            colors: designData.playerName.color
        },
        'playerNameOutlineColor': {
            id: 'playerNameOutlineColor',
            title: 'Outline Color',
            parent: 'playerName',
            template: 'submenu/colors.html',
            colors: designData.playerName.outlineColor
        },
        'playerNameLayout': {
            id: 'playerNameLayout',
            title: 'Layout',
            parent: 'playerName',
            template: 'submenu/layouts.html',
            layouts: designData.playerName.layout
        }
    };

    menuDataMap.colors.submenuList = _.filter(menuDataMap, {parent:'colors'})

    function appendSubmenus(menuMap) {
        _.each(menuMap, function (menuData, menuID) {
            var parentMenuID = menuData.parent,
                parentMenu = menuMap[parentMenuID];

            if (!parentMenuID) {
                return;
            }

            if (parentMenuID === 'logo') {
                return;
            }

            if (!_.has(parentMenu, 'submenus')) {
                parentMenu['submenus'] = [];
            }

            parentMenu['submenus'].push(menuID);
        });

        return menuMap;
    }

    function appendBreadcrumb(menuMap) {

        _.each(menuMap, function(menuData, menuId) {
            var parent = menuData.parent && menuMap[menuData.parent];
            menuMap[menuId].breadcrumb || (menuMap[menuId]['breadcrumb'] = []);

            while(parent) {
                menuMap[menuId].breadcrumb.push(parent);
                parent = parent.parent && menuMap[parent.parent];
            }

            menuMap[menuId].breadcrumb.reverse();
        });

        return menuMap;
    }


    var menuDataMap = appendBreadcrumb(appendSubmenus(menuDataMap));
    return menuDataMap;
});
