'use strict';

angular.module('mainApp').directive('garmentRemoveMarker', function(_, menuDataMap, designData) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            element.bind('click', function (e) {
                e.stopPropagation();
            });

            scope.removeGarment = function(key) {
                designData.garmentList.remove(key);
            };
        }
    }
});
