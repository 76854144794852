'use strict';

angular.module('mainApp').factory('logoPositionChooser', function () {


    function getPreferrablePosition(logoType) {
        return 'none';
    }

    return {
        'getPreferrablePosition': getPreferrablePosition
    }
});
