'use strict';

angular.module('mainApp').directive('garmentStateMarker', function(_, menuDataMap, designData, activeGarmentService, designDataUtils) {
    return {
        restrict: 'A',
        link: function(scope, element) {

            element.bind('click', function (e) {
                e.stopPropagation();
            });

            scope.activateGarment = function(key) {
                activeGarmentService.set(key);
            };

            scope.isActiveGarment = function(key) {
                return activeGarmentService.key() === key;
            }
        }
    }
});
